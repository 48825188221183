<template>
    <div
        :ref="'consoleWrapper'"
        class="w-full flex-1">
        <div class="h-full flex flex-col">
            <div class="h-12 bg-codesample-dark flex flex-row justify-between items-center">
                <div class="flex flex-row justify-start">
                    <span
                        class="text-gray-500 cursor-pointer transition duration-200 ease-in-out hover:translate-x-2px hover:text-gray-100"
                        @click="openConsole">
                        <span class="pointer-events-none flex flex-row tracking-wide text-sm px-4">
                            Example Response
                        </span>
                    </span>
                </div>
                <div class="flex flex-row justify-end">
                    <span
                        class="text-gray-500 cursor-pointer transition duration-200 ease-in-out hover:translate-x-2px hover:text-gray-100 px-4"
                        @click="toggleConsole">
                        <caretDownSvg
                            class="h-5 mr-1 fill-current transform"
                            :class="{
                                'rotate-180': !consoleOpen,
                            }"/>
                    </span>
                </div>
            </div>
            <div class="flex-1 bg-codesample w-full">
                <div
                    class="response-sample-content bg-codesample h-full overflow-y-auto"
                    :ref="'responseSampleContent'">
                    <VueMarkdown
                        v-if="activeSample"
                        :source="activeSample.content"
                        @rendered="onMarkdownRendered" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
import VueMarkdown from 'vue-markdown';

import hljs from 'highlight.js/lib/core';

import json from 'highlight.js/lib/languages/json';

hljs.registerLanguage('curl', json);

// FIXME::ckarundu Explore escaping html instead
hljs.configure({ ignoreUnescapedHTML: true });

export default {
    name: 'CodeResponseSamples',

    props: {
        responseSamples: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            consoleOpen: true,
        };
    },

    computed: {
        activeSample() {
            return this.responseSamples[0];
        },
    },

    methods: {
        highlightCodeSamples() {
            this.$refs.responseSampleContent.querySelectorAll('pre code').forEach((block) => {
                hljs.highlightElement(block);
            });
        },

        onMarkdownRendered() {
            this.$nextTick(() => {
                this.highlightCodeSamples();
                this.$refs.responseSampleContent.scrollTop = 0;
            });
        },

        toggleConsole() {
            if (!this.consoleOpen) {
                this.openConsole();
            } else {
                this.closeConsole();
            }
        },

        openConsole() {
            const el = this.$refs.consoleWrapper;

            const first = el.getBoundingClientRect();
            el.classList.add('flex-1');

            const last = el.getBoundingClientRect();
            const invert = first.top - last.top;

            el.style.transform = `translateY(${invert}px)`;

            gsap.to(el, {
                duration: 0.3,
                translateY: 0,
                ease: 'power1.in',
                onComplete: () => {
                    this.consoleOpen = true;
                },
            });
        },

        closeConsole() {
            const el = this.$refs.consoleWrapper;

            const first = el.getBoundingClientRect();
            el.classList.remove('flex-1');

            const last = el.getBoundingClientRect();
            const invert = Math.abs(first.top - last.top);

            el.style.height = `${first.height}px`;

            gsap.to(el, {
                duration: 0.3,
                translateY: `${invert}px`,
                ease: 'power1.in',
                onComplete: () => {
                    el.style.transform = '';
                    el.style.height = `${last.height}px`;
                    this.consoleOpen = false;
                },
            });
        },
    },

    components: {
        VueMarkdown,
        caretDownSvg: () => import('@/assets/img/caretDown.svg'),
    },
};
</script>

<style lang="postcss">
.response-sample-content {
    overscroll-behavior: contain;

    & > div {
        min-height: 100%;
        position: relative;

        & > pre {
            top: 0;
            bottom: 0;
            width: 100%;
            position: absolute;

            & > code {
                min-height: 100%;
                font-size: .875rem;
                padding: 0;
                padding: 1rem;
                background-color: theme('colors.codesample');
                color: theme('colors.gray.100');
            }
        }
    }
}
</style>
